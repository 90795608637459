import { graphql } from "gatsby";
import React, { Children } from "react";
import "../../index.scss";
import { Section, Container, Content } from "react-bulma-components";

import Layout from "../../components/layouts/main-layout/index.jsx";

import BandSinglePropTypes from "./types";
import Band from "../../components/bits/band";
import { get } from "lodash";
import jsonldBand from "../../utils/helpers/jsonld-band";
import jsonldEvent from "../../utils/helpers/jsonld-event";

export default function BandSingle({ data, pageContext }) {
  if (!pageContext) {
    return null;
  }

  const {
    title,
    lineUp,
    images,
    shortDescription,
    description,
    edition,
    socialMedia,
  } = data.contentfulBand;

  const lineUpWithAvatar = lineUp?.filter(({ avatar }) => !!avatar);

  const lineUpFirstAvatar =
    lineUpWithAvatar?.length > 0 ? lineUpWithAvatar[0].avatar : null;

  const cover = images ? images[0] : lineUpFirstAvatar;

  return (
    <Layout
      heroImage={cover}
      title={title}
      description={shortDescription}
      socialMedia={socialMedia}
      logoNavigation={data.logoNavigation}
      jsonLdData={[
        jsonldBand(data.contentfulBand),
        ...(data.contentfulBand?.event?.map((event) => jsonldEvent(event)) ||
          []),
      ]}
    >
      <div>
        {description && (
          <Section>
            <Container>
              <Content
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            </Container>
          </Section>
        )}
        <Band {...data.contentfulBand} aside />
      </div>
    </Layout>
  );
}

BandSingle.propTypes = BandSinglePropTypes;

export const bandData = graphql`
  fragment BandData on ContentfulBand {
    id
    internal {
      type
    }
    slug
    title
    shortDescription {
      childMarkdownRemark {
        html
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }

    lineUp {
      avatar {
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      person {
        name
        slug
        musician {
          band {
            title
            slug
            edition {
              year
            }
          }
        }
      }
      instrument {
        name
        id
      }
    }
    edition {
      year
    }
    images {
      description
      gatsbyImageData(layout: FULL_WIDTH)
    }
    socialMedia {
      type
      url
    }
  }
`;

export const query = graphql`
  query BandBySlug($slug: String!) {
    logoNavigation: file(extension: {}, name: { eq: "logo-navigation" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    contentfulBand(slug: { eq: $slug }) {
      event {
        ...EventData
      }
      ...BandData
    }
  }
`;
